/* You can add global styles to this file, and also import other style files */
app-root
    height: 100% !important

app-login, app-register, app-forgot, app-dashboard, app-personal-information, pdf-page
    width: 100% !important

.error-text
    color: rgba(126,5,8,1) !important
    font-size: large !important

.btn-error-ok
    color: rgba(126,5,8,1) !important
    background-color: rgba(255,255,255,0.7) !important    
    font-size: small !important

.warning-text
    color: rgba(152,87,18,1) !important
    font-size: large !important

.btn-warning-ok
    color: rgba(152,87,18,1) !important
    background-color: rgba(255,255,255,0.7) !important    
    font-size: small !important

.sidebar-enabled[data-sidebar=on] .wrapper
    margin-right: unset !important

.btn.btn-hover-primary:not(:disabled):not(.disabled):hover:not(.btn-text), .btn.btn-hover-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-hover-primary.dropdown-toggle, .show .btn.btn-hover-primary.btn-dropdown
    color: white !important
    background-color: rgb(255, 255, 255, 0.5) !important
    border-color: rgb(255, 255, 255, 0.5) !important

#kt_aside .nav-link:hover
    color: white !important
    background-color: rgb(255, 255, 255, 0.5) !important
    border-color: rgb(255, 255, 255, 0.5) !important

.offcanvas-mobile
    left: -5000px !important

.btn-new
    background-color: rgb(246,122,120) !important
    border-color: rgb(246,122,120) !important
    color: white !important

.btn-new:hover
    background-color: rgb(243, 75, 72) !important
    border-color: rgb(243, 75, 72) !important
    color: white !important


.ui-popup-success
    border: 2px solid #a5dc86 !important

.ui-popup-warning
    border: 2px solid #facea8 !important

.ui-popup-error
    border: 2px solid #f27474 !important
    min-width: 80% !important
    max-width: 600px !important

.ui-popup-info
    border: 2px solid #9de0f6 !important

.ui-popup-question
    border: 2px solid #87adbd !important

.swal2-styled.swal2-confirm
    background-color: rgb(243, 75, 72) !important

input:focus, select:focus, textarea:focus
    border-color: red !important

::-webkit-scrollbar 
    width: 12px
  
::-webkit-scrollbar-track 
    background: var(--bg-black)
    width: 12px
  
::-webkit-scrollbar-thumb 
    border-radius: 10px
    background: rgb(243, 75, 72)
    &:hover 
       background: rgb(243, 75, 72)
    &:active 
       background: rgb(243, 75, 72)

.swal2-styled.swal2-confirm
    background-color: white !important
    border: 2px solid green !important
    color: black !important
    border-radius: 12px !important
    min-width: 85px

.swal2-styled.swal2-cancel
    background-color: white !important
    border: 2px solid rgb(243, 75, 72) !important
    color: black !important
    border-radius: 12px !important